<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
      stateless
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title primary--text">
            <v-img
              contain
              src="./assets/FlowerCircle.svg" />
          </v-list-item-title>
          <v-list-item-subtitle>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />
      <menu-drawer />
      <v-divider />
      <v-spacer />
    </v-navigation-drawer>
    <div class="container hidden-md-and-down">
      <v-app-bar
        app
        color="white"
        flat
        fixed
        height="120px"
        style="background-color: white !important"
      >
        <v-app-bar-nav-icon
          @click="drawer = !drawer"
        />
        <div class="container menu">
          <v-row>
            <v-col cols="12" lg="4" xl="4" class="mt-2 mb-2">
              <v-img
                width="100%"
                class="title-image"
                contain
                src="./assets/skillosophia-logo.png" />
            </v-col>
            <v-col class="hidden-md-and-down" cols="5" justify-center>
              <MenuButtons />
            </v-col>
            <v-col class="hidden-md-and-down" cols="3">
              <Login />
            </v-col>
          </v-row>
        </div>
      </v-app-bar>
    </div>
    <v-app-bar
        app
        color="white"
        flat
        fixed
        height="60px"
        style="background-color: white !important"
        class="hidden-lg-and-up"
      >
        <v-app-bar-nav-icon
          @click="drawer = !drawer"
        />
        <v-img
          width="100%"
          max-height="50px"
          class="title-image"
          contain
          src="./assets/skillosophia-logo.png" />
    </v-app-bar>
    <div>
      <v-main class="container">
        <div id="core-view">
          <v-fade-transition mode="out-in">
            <router-view />
          </v-fade-transition>
        </div>
      </v-main>
    </div>
    <v-footer
      color="white"
    >
      <FooterButtons />
    </v-footer>
  </v-app>
</template>

<script>
import Login from './components/Login.vue';
import MenuButtons from './components/MenuButtons.vue';
import FooterButtons from './components/FooterButtons.vue';
import MenuDrawer from './components/MenuDrawer.vue';

export default {
  components: {
    Login, FooterButtons, MenuButtons, MenuDrawer,
  },
  name: 'App',
  data() {
    return {
      pages: [],
      drawer: false,
    };
  },
  computed: {
    imageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 220;
        case 'sm': return 400;
        case 'md': return 500;
        case 'lg': return 600;
        case 'xl': return 800;
        default: return 500;
      }
    },
  },
};
</script>

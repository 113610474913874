<template>
  <div style="width: 100%">
    <v-row no-gutters>
      <v-col cols="12" lg="5" xl="5" md="5" class="footer-social-buttons">
        <v-btn
          color="primary"
          class="ma-2 white--text"
          fab
          elevation="0"
          v-for="(btn, index) in buttons"
          :key="`btn-${index}`"
        >
          <v-icon color="white" large>
            {{ btn.icon }}
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" lg="2" xl="2" md="2" class="text-center primary--text pt-5">
        <v-img class="footer-flower" src="../assets/FlowerCircle.svg" contain />
        <div class="footer-flower-text">by Skillosophia</div>
      </v-col>
      <v-col cols="12" lg="5" xl="5" md="5" class="footer-links">
        <v-btn text color="secondary">
          {{ $t('footer.privacy') }}
        </v-btn>
        <br />
        <v-btn text color="secondary">
          {{ $t('footer.subscriptionPolicy') }}
        </v-btn>
        <br />
        <v-btn text color="secondary">
          {{ $t('footer.terms') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable global-require */

export default {
  name: 'MenuButtons',
  data() {
    return {
      buttons: [
        {
          icon: 'mdi-linkedin',
        },
        {
          icon: 'mdi-facebook',
        },
        {
          icon: 'mdi-instagram',
        },
      ],
    };
  },
};
</script>
<style scoped>
.footer-flower {
  display: inline-block;
  height: 32px;
  width: 32px;
  margin-right: 5px;
}
.footer-flower-text {
  display: inline-block;
  vertical-align: top;
  margin-top: 7px;
}
</style>

<template>
  <div>
    <v-row>
      <v-col cols="12" class="text-right">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text color="primary" dark
              v-bind="attrs"
              v-on="on">
              {{ locale }}
              <v-icon>{{ mdiMenuDown }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="item in localeList"
              :key="item.locale"
              @click="switchLocale(item.locale)"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <br />
          <v-btn
            outlined
            color="secondary"
            class="mt-5"
            href="https://app.skillosophia.com" nofollow>
            {{ $t('menu.start')}}
          </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMenuDown } from '@mdi/js';
import localeList from '../localeList.json';

export default {
  name: 'Login',
  data() {
    return {
      mdiMenuDown,
      localeList,
    };
  },
  computed: {
    locale() {
      const value = this.$i18n.locale ?? this.value;
      return this.localeList.find((l) => l.locale === value)?.name;
    },
  },
  methods: {
    /** Method used to switch the locale
     */
    switchLocale(locale) {
      this.$root.$i18n.locale = locale;
      this.locale = locale;
      /** Triggered when locale is changed.
      * @event input
      * @type {string}
      */
      this.$emit('input', locale);
    },
  },
};
</script>

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#8F6284',
        secondary: '#EB6510',
        accent: '#EB6510',
        error: '#F44336',
        info: '#613757',
        success: '#4CAF50',
        warning: '#FBC02D',
      },
    },
  },
});

<template>
  <v-list
      dense
      nav
    >
    <v-list-item
      v-for="(btn, index) in buttons"
      :key="`btn-${index}`"
      style="min-height: 80px;"
    >
      <v-list-item-icon>
        <a class="menu-button" :href="btn.href">
        <v-hover
          v-slot="{ hover }"
        >
          <v-img v-if="hover" :src="btn.iconHover" contain height="64"/>
          <v-img v-else :src="btn.icon" contain height="64"/>
        </v-hover>
        </a>
      </v-list-item-icon>
      <v-list-item-content>
        <a class="menu-button" :href="btn.href">
          {{ btn.text }}
        </a>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
/* eslint-disable global-require */

export default {
  name: 'MenuDrawer',
  computed: {
    /**
     * Returns the buttons property
     */
    buttons() {
      return [
        {
          icon: require('../assets/menuHome.svg'),
          iconHover: require('../assets/menuHome-hover.svg'),
          text: this.$t('menu.home'),
          href: '#hero',
        },
        {
          icon: require('../assets/menuAbout.svg'),
          iconHover: require('../assets/menuAbout-hover.svg'),
          color: '#DDCCDC',
          href: '#about',
          text: this.$t('menu.about'),
        },
        {
          icon: require('../assets/menuTeam.svg'),
          iconHover: require('../assets/menuTeam-hover.svg'),
          color: '#8F6283',
          href: '#about-us',
          text: this.$t('menu.team'),
        },
        {
          icon: require('../assets/menuConnect.svg'),
          iconHover: require('../assets/menuConnect-hover.svg'),
          color: '#B89CB3',
          href: '#contact-us',
          text: this.$t('menu.connect'),
        },
        {
          icon: require('../assets/menuInstagram.svg'),
          iconHover: require('../assets/menuInstagram-hover.svg'),
          color: '#8F6283',
          href: '#social',
          text: this.$t('menu.instagram'),
        },
      ];
    },
  },
};
</script>

<template>
  <v-row>
    <v-col cols="2"
      class="text-center"
      v-for="(btn, index) in buttons"
      :key="`btn-${index}`">
    <a class="menu-button" @click="nav(btn.href)">
      <v-hover
        v-slot="{ hover }"
      >
        <v-img v-if="hover" :src="btn.iconHover" contain height="64"/>
        <v-img v-else :src="btn.icon" contain height="64"/>
      </v-hover>
      {{ btn.text }}
    </a>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable global-require */

export default {
  name: 'MenuButtons',
  computed: {
    /**
     * Returns the buttons property
     */
    buttons() {
      return [
        {
          icon: require('../assets/menuHome.svg'),
          iconHover: require('../assets/menuHome-hover.svg'),
          text: this.$t('menu.home'),
          href: '#hero',
        },
        {
          icon: require('../assets/menuAbout.svg'),
          iconHover: require('../assets/menuAbout-hover.svg'),
          color: '#DDCCDC',
          href: '#about',
          text: this.$t('menu.about'),
        },
        {
          icon: require('../assets/menuTeam.svg'),
          iconHover: require('../assets/menuTeam-hover.svg'),
          color: '#8F6283',
          href: '#about-us',
          text: this.$t('menu.team'),
        },
        {
          icon: require('../assets/menuConnect.svg'),
          iconHover: require('../assets/menuConnect-hover.svg'),
          color: '#B89CB3',
          href: '#contact-us',
          text: this.$t('menu.connect'),
        },
        {
          icon: require('../assets/menuInstagram.svg'),
          iconHover: require('../assets/menuInstagram-hover.svg'),
          color: '#8F6283',
          href: '#social',
          text: this.$t('menu.instagram'),
        },
      ];
    },
  },
  methods: {
    nav(id) {
      const yOffset = -140;
      const element = document.getElementById(id.replace('#', ''));
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    },
  },
};
</script>
